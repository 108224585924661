<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12" v-if="propertyLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-else>
          <section>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="searchQuery"
                  label="Search by Address"
                  class="mb-4"
                  @input="filterProperties"
                ></v-text-field>
                <h3 class="table-heading flexBox-space">
                  <span>Published Properties</span>
                  <span class="d-flex align-center">
                    Total Properties: {{ filteredProperties.length }}
                  </span>
                </h3>
                <v-simple-table class="tableborder">
                  <thead>
                    <tr>
                      <td colspan="15" class="pagination-header">
                        <div class="d-flex flexend align-center">
                          <v-btn @click="prevPage" :disabled="currentPage === 1">
                            <v-icon>mdi-chevron-left</v-icon> Previous
                          </v-btn>
                          <strong class="px-3">
                            {{ currentStartIndex }} - {{ currentEndIndex }}
                          </strong>
                          <v-btn @click="nextPage" :disabled="currentEndIndex >= filteredProperties.length">
                            Next <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>S/N</th>
                      <th width="200">Address</th>
                      <th class="relRow">Bedrooms</th>
                      <th class="relRow">Price</th>
                      <th class="relRow" width="200">Postcode</th>
                      <th class="relRow">Gross Yield</th>
                      <th>Rent</th>
                      <th>Tenure Type</th>
                      <th class="relRow">Property Type</th>
                      <th class="relRow">Market</th>
                      <th class="relRow">Reservation Status</th>
                      <th class="relRow">Reservation Price</th>
                      <th>Action</th>
                      <th>Reserve</th>
                      <th>Image Fix</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-if="paginatedProperties.length === 0">
                      <td colspan="15">No Property Found</td>
                    </tr>
                    <tr
                      v-for="(data, index) in paginatedProperties"
                      :key="data._id"
                    >
                      <td>{{ index + currentStartIndex }}</td>
                      <td>
                        {{ data.doorNumber }}, {{ data.propertyAddress.displayAddress }}
                      </td>
                      <td>{{ data.bedrooms }}</td>
                      <td>£{{ numberFormat(data.price) }}</td>
                      <td>{{ data.postcode }}</td>
                      <td>{{ data.rentGrossYield }}%</td>
                      <td>£{{ numberFormat(data.averageRent) }}</td>
                      <td>{{ data.tenureType }}</td>
                      <td>{{ data.houseSubtype }}</td>
                      <td>{{ data.propertyFrom }}</td>
                      <td>
                        <span v-if="data.reserved">Reserved by {{ data.reservedFrom }}</span>
                        <span v-else>Not Reserved</span>
                      </td>
                      <td>£{{ numberFormat(data.reservationPrice) }}</td>

                      <td>
                        <v-btn
                          :disabled="data.reserved"
                          @click="confirmAndPublish(data.connectedTo)"
                        >
                          Unpublish
                        </v-btn>
                      </td>
                      <td>
                        <v-btn
                          @click="toggleReserve(data._id, data.reserved)"
                        >
                          {{ data.reserved ? 'Unreserve' : 'Reserve' }}
                        </v-btn>
                      </td>
                      <td>
                        <div class="d-flex flex-row align-center">
                          <v-icon class="hover-icon" title="Fix Image" @click="getFixImage(data.connectedTo)">
                            mdi-auto-fix
                          </v-icon>
                          <a
                            :href="`/dashboard/publishedproperty/${data._id}`"
                            target="_blank"
                            class="mr-3"
                          >
                            <v-icon class="hover-icon" title="View Image">mdi-eye</v-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15" class="pagination-footer">
                        <div class="d-flex flexend align-center">
                          <v-btn @click="prevPage" :disabled="currentPage === 1">
                            <v-icon>mdi-chevron-left</v-icon> Previous
                          </v-btn>
                          <strong class="px-3">
                            {{ currentStartIndex }} - {{ currentEndIndex }}
                          </strong>
                          <v-btn @click="nextPage" :disabled="currentEndIndex >= filteredProperties.length">
                            Next <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

export default {
  name: "OffMarketProperties",
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["allImportCSV"]),
    filteredProperties() {
      return this.properties.results
        .filter(property =>
          property.propertyAddress.displayAddress
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by newest added
    },
    paginatedProperties() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.filteredProperties.slice(start, end);
    },
    currentStartIndex() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    currentEndIndex() {
      return Math.min(this.currentPage * this.itemsPerPage, this.filteredProperties.length);
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      properties: [],
      searchQuery: "",
      importLoading: false,
      propertyLoading: true,
      currentPage: 1,
      itemsPerPage: 50,
    };
  },
  methods: {
    ...mapActions([
      "getPublishedProperties",
      "postPropertyPost",
      "postPropertyReserve",
      "postPropertyUnReserve",
      "getFixImage",
    ]),
    async onCsvPicked(event) {
      this.importLoading = true;
      const files = event.target.files[0];
      const formData = new FormData();
      formData.append("csv", files);
      await this.postImportCSV(formData);
      this.snackbar = true;
      this.text = this.allImportCSV.message;
      this.importLoading = false;
    },
    numberFormat(givenNumber) {
      return new Intl.NumberFormat("en-GB", { minimumFractionDigits: 0 }).format(givenNumber);
    },
    async confirmAndPublish(id) {
      if (confirm("Are you sure you want to unpublish this property?")) {
        await this.onPublish(id);
      }
    },
    async onPublish(id) {
      this.propertyLoading = true;
      await this.postPropertyPost({ _id: id });
      this.snackbar = true;
      this.text = this.allImportCSV.message;
      await this.refreshProperties();
    },
    async toggleReserve(id, status) {
      if (confirm(`Are you sure you want to ${status ? 'unreserve' : 'reserve'} this property?`)) {
        this.propertyLoading = true;
        if (!status) {
          await this.postPropertyReserve({ connectedTo: id, paymentStatus: "success" });
        } else {
          await this.postPropertyUnReserve({ connectedTo: id, paymentStatus: "success" });
        }
        this.snackbar = true;
        this.text = this.allImportCSV.message;
        await this.refreshProperties();
      }
    },
    async refreshProperties() {
      await this.getPublishedProperties({ limit: 1000, page: 1 });
      this.properties = this.allImportCSV;
      this.propertyLoading = false;
    },
    filterProperties() {
      // This function is intentionally left empty as the filtering is handled by computed property
      this.currentPage = 1; // Reset to first page when search query changes
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentEndIndex < this.filteredProperties.length) {
        this.currentPage++;
      }
    }
  },
  async created() {
    await this.refreshProperties();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.dateHighlight {
  background: yellow;
}

.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}

h4 {
  margin-bottom: 0;
}

.v-icon {
  font-size: 16px;
  cursor: pointer;
}

.hover-icon:hover {
  color: #3f51b5; /* Change this to the color you want on hover */
}

.downloadbtn {
  margin-left: 20px;
}

.fixBoxTop {
  position: fixed;
  top: 20px;
  z-index: 99999;
}

.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;

  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
    }
  }
}

.heightZero {
  height: 0;
}

.actionbtn {
  margin-bottom: 20px;
}

.activeTab {
  background: rgb(195, 195, 196);
  color: rgb(20, 20, 20);

  a {
    color: rgb(5, 5, 5);
  }
}

.mr-btm {
  margin-bottom: 30px;
  margin-top: -40px;
}

a {
  text-decoration: none;
}

.mr-left {
  margin-left: 10px;
}

.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;

  li {
    list-style: none;
    padding: 8px 20px;
  }
}

.wishlistBtn {
  position: fixed;
  top: 0px;
  right: 370px;
  z-index: 999;
  transition: 0.3s all ease-in-out;
}

.dropBox {
  list-style: none;
  padding-left: 0;
  position: absolute;
  background: #fff;
  box-shadow: 0 5px 10px #efefef;

  li {
    padding: 10px 25px;
    border-bottom: 1px solid #dfdddd;
    cursor: pointer;

    &:last-of-type {
      border: none;
    }
  }
}

.relRow {
  position: relative;

  .dropBox {
    position: absolute;
    width: 150px;
    display: none;
  }

  &:hover {
    .dropBox {
      display: block;
    }
  }
}

.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }

  .formBox {
    background: #fff;
    border-radius: 10px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    font-size: 14px;

    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}

.rightmovePopup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    width: 500px;
    height: 500px;
  }
}

.flexBox-space {
  display: flex;
  justify-content: space-between;
}

.iconSection {
  .v-icon {
    font-size: 30px;
  }
}

.dash {
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.d-flex {
  .justify-space-between {
    span {
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.flexend {
  justify-content: flex-end;

  .v-btn {
    margin-left: 10px;
  }
}

.ulTop {
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding-top: 10px;

  h5 {
    font-size: 16px;
    margin-bottom: 5px;

    @media only screen and (max-width: 1369px) {
      font-size: 13px;
    }
  }

  margin-bottom: 20px;

  li {
    box-shadow: 0 3px 5px #efefef;
    padding: 20px 40px;
    margin-right: 15px;
    width: 100%;

    span {
      font-size: 14px;
      display: block;
      padding: 5px 0;

      @media only screen and (max-width: 1369px) {
        font-size: 12px;
      }
    }
  }
}

.btnActReset {
  width: 90px;
}

.resetBtn {
  color: blue;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }
}

.filterBtn {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  min-height: 60px;
  width: 50px;
  bottom: 50px;
}

.formInside {
  margin: 0;
  z-index: 999999;
  width: 150px !important;
  right: 200px;
  top: 15px;
  position: fixed;
}

.leftSec {
  margin: 0;
  z-index: 999999;
  position: fixed;
  top: 15px !important;
  width: 150px !important;

  &:nth-of-type(1) {
    left: 280px;
  }

  &:nth-of-type(2) {
    left: 420px;
  }
}

.favourite {
  color: red;
  width: 35px;
  height: 35px;
}

.favouriteO {
  width: 35px;
  height: 35px;
}

.addButton {
  min-width: 200px !important;
}

.pagination-header, .pagination-footer {
  text-align: right;
}
</style>
