<template>
  <section>
    <v-row>
      <v-col md="12">
        <div>
          <h4>
            Filter For Property Data
            <v-icon v-if="!filtersection" @click="toggleFilter">
              mdi-chevron-down </v-icon
            ><v-icon v-if="filtersection" @click="toggleFilter">
              mdi-chevron-up
            </v-icon>
          </h4>
        </div>
        <div v-if="filtersection">
          <v-form>
            <v-row>
              <v-col md="6" class="no-pad-tb">
                <v-text-field
                  v-model="area"
                  label="Search property with keywords "
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="6" class="no-pad-tb">
                <v-autocomplete
                  :items="allPrefixPostCode.results"
                  v-model="prefixpostcode"
                  :search-input.sync="changePrefixCode"
                  label="Prefix Post Code"
                  outlined
                  item-text="postcode"
                  item-value="postcode"
                ></v-autocomplete>
              </v-col>

              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="prefixpostcode"
                  label="Post Code"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="5" class="no-pad-tb">
                <v-select
                  :items="propertyTypes"
                  v-model="propertyType"
                  label="Property Type"
                  outlined
                  multiple
                >
                </v-select>
              </v-col>

              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="soldStcs"
                  v-model="soldstc"
                  label="Sold STC"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="isListingLives"
                  v-model="isListing"
                  item-text="text"
                  item-value="val"
                  label="IsListingLive"
                  outlined
                >
                </v-select>
              </v-col>
              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="minprice"
                  label="Min Price"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="maxprice"
                  label="Max Price"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="minbed"
                  label="Min Bed"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="maxbed"
                  label="Max Bed"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="minLhaYield"
                  label="Min LHA Yield"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="3" class="no-pad-tb">
                <v-text-field
                  v-model="minRentYield"
                  label="Min Rent Yield"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="austionProperties"
                  v-model="auctionProperty"
                  label="Auction Properties"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>

              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="newbuilds"
                  v-model="newbuild"
                  label="New Build"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="sharedOwnerships"
                  v-model="sharedOwnership"
                  label="Shared Ownership"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="retirementHomes"
                  v-model="retirementHome"
                  label="Retirement Home"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>

              <v-col md="2" class="no-pad-tb" v-if="false">
                <v-text-field
                  v-model="area"
                  label="Door Number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="needRefurbishments"
                  v-model="needRefurbishment"
                  label="Need Refurbishment"
                  outlined
                  item-text="text"
                  item-value="val"
                ></v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="isParkHomes"
                  v-model="isParkHome"
                  label="Is Park Home"
                  outlined
                  item-text="text"
                  item-value="val"
                >
                </v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="regions"
                  v-model="region"
                  label="Region"
                  outlined
                  item-text="text"
                  item-value="val"
                  multiple
                >
                </v-select>
              </v-col>
              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="websites"
                  v-model="website"
                  label="Website"
                  outlined
                  item-text="text"
                  item-value="val"
                  multiple
                >
                </v-select>
              </v-col>

              <v-col md="2" class="no-pad-tb">
                <v-select
                  :items="optionOnProperties"
                  v-model="optionOnProperty"
                  label="Email Status"
                  outlined
                ></v-select>
              </v-col>
              <v-col md="12" class="px-0">
                <v-btn class="downloadbtn" @click="filterData">Filter</v-btn>
                <span v-if="!emailSent">
                  <v-btn
                    class="downloadbtn"
                    v-if="!loadingEmailInquiry"
                    @click="sendInquiry"
                    >Property Inquiry</v-btn
                  >
                  <v-btn class="downloadbtn" v-if="loadingEmailInquiry"
                    >Sending Inquiry ...</v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-col>

      <v-col md="12" v-if="!allLoadingProperty">
        <Loading />
      </v-col>

      <v-col md="12" v-if="allLoadingProperty">
        <section>
          <v-row>
            <v-col md="12" v-if="allNorthSideDetails.results != undefined">
              <h3 class="table-heading">Property Detail Data</h3>
              <v-simple-table class="tableborder">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Address</th>
                    <th>Property Link</th>
                    <th>Price</th>
                    <th>Bedrooms</th>
                    <th>House Type</th>
                    <th>Lha Rent</th>
                    <th>Rent Avg</th>
                    <th>Price Valuations</th>
                    <th>Rent Confidence</th>
                    <th>PVC</th>
                    <th>LGY</th>
                    <th>RGY</th>
                    <th>Email Status</th>
                    <th>Email Action</th>
                  </tr>
                </thead>
                <tbody v-if="allNorthSideDetails.results != undefined">
                  <tr
                    v-for="(data, index) in allNorthSideDetails.results"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ data.propertyAddress }}
                    </td>
                    <td>
                      <a
                        v-if="data.website == 'rightmoves'"
                        target="_blank"
                        :href="`https://www.rightmove.co.uk/properties/${data.propertyId}`"
                        >Explore</a
                      >
                      <a
                        v-if="data.website == 'zoopla'"
                        target="_blank"
                        :href="`https://www.zoopla.co.uk/for-sale/details/${data.propertyId}`"
                        >Explore</a
                      >
                    </td>
                    <td>{{ data.price }}</td>
                    <td>{{ data.bedrooms }}</td>
                    <td>{{ data.houseSubtype }}</td>
                    <td>
                      {{
                        data.lhaRent != undefined
                          ? data.lhaRent.toFixed(0)
                          : "N/A"
                      }}
                    </td>

                    <td>{{ data.averageRent }}</td>
                    <td>{{ data.averagePriceValution }}</td>

                    <td>
                      <template>
                        <span v-if="data.rentValutionConfidence <= 2"
                          >High</span
                        >
                        <span
                          v-if="
                            data.rentValutionConfidence > 2 &&
                            data.rentValutionConfidence <= 5
                          "
                          >Medium</span
                        >
                        <span v-if="data.rentValutionConfidence > 5">Low</span>
                      </template>
                    </td>
                    <td>
                      <template>
                        <span v-if="data.priceValutionConfidence <= 2"
                          >High</span
                        >
                        <span
                          v-if="
                            data.priceValutionConfidence > 2 &&
                            data.priceValutionConfidence <= 5
                          "
                          >Medium</span
                        >
                        <span v-if="data.priceValutionConfidence > 5">Low</span>
                      </template>
                    </td>
                    <td>
                      {{
                        data.lhaGrossYield != undefined
                          ? data.lhaGrossYield
                          : "N/A"
                      }}
                    </td>
                    <td>
                      {{
                        data.rentGrossYield != undefined
                          ? data.rentGrossYield
                          : "N/A"
                      }}
                    </td>
                    <td>
                      <div>
                        {{ data.emailStatus }}
                      </div>
                    </td>
                    <td>
                      <div>
                        <v-icon @click="popAction(data)"> mdi-pencil </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <ul class="pagination-button">
                <li>
                  Page :
                  {{
                    (parseInt(allNorthSideDetails.pagination.next) - 1) *
                    parseInt(limit)
                  }}
                  -
                  {{ allNorthSideDetails.pagination.total }}
                </li>
                <li>
                  <div>
                    <v-btn
                      v-if="allNorthSideDetails.pagination.previous"
                      @click="prevPage(allNorthSideDetails.pagination.previous)"
                      >Prev</v-btn
                    >

                    <v-btn
                      v-if="allNorthSideDetails.pagination.next"
                      @click="nextPage(allNorthSideDetails.pagination.next)"
                      >Next</v-btn
                    >
                  </div>
                </li>
              </ul>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
    <div class="fixPop" v-if="popStatus">
      <div class="formBox">
        <v-icon @click="popAction"> mdi-close </v-icon>
        <v-row>
          <ul class="tabList">
            <li
              :class="{ active: tab == 'formtab' }"
              @click="tabAction('formtab')"
            >
              Add Comment
            </li>
            <li
              :class="{ active: tab == 'formlist' }"
              @click="tabAction('formlist')"
            >
              List of comment
            </li>
          </ul>
          <div class="width100">
            <div v-if="tab == 'formlist'">
              Comment list
              <ul class="commentTab">
                <li
                  v-for="(data, index) in allComments.results"
                  :key="index + 'comment'"
                >
                  <strong>{{ data.userName }}</strong>
                  <p>
                    {{ data.comment }}
                    <br />
                    <strong>Offer Price : </strong>{{ data.offerPrice }}
                    <br />
                    <strong>Price : </strong>{{ data.priceValuation }}
                    <br />
                    <strong>Lha Rent : </strong>{{ data.lhaRent }}
                    <br />
                  </p>
                </li>
              </ul>
            </div>
            <v-form ref="propertyAction" v-if="tab == 'formtab'">
              <v-col
                ><h3>Property Update</h3>
                <div class="mb-8">
                  <div>
                    <strong>Email Status: </strong>
                    {{ singleProperty.emailStatus }}
                  </div>
                  <div>
                    <strong>Email Reply : </strong>
                    {{ singleProperty.emailReply }}
                  </div>
                </div>
              </v-col>

              <v-col md="12" class="pt-0 pb-0 marginMinus mx-0">
                <div class="flexBoxes">
                  <div class="width50">
                    <v-select
                      :items="optionOnProperties"
                      v-model="optionOnProperty"
                      label="Email Status"
                      outlined
                    ></v-select>
                  </div>
                  <div class="width50">
                    <v-text-field
                      v-model="offerPrice"
                      placeholder="Offer Price"
                      label="Offer Price"
                      outlined
                    ></v-text-field>
                  </div>
                  <div class="width50">
                    <v-text-field
                      v-model="lhaRent"
                      placeholder="LHA Rent"
                      label="LHA Rent"
                      outlined
                    ></v-text-field>
                  </div>
                  <div class="width50">
                    <v-text-field
                      v-model="rentValuation"
                      placeholder="Rent"
                      label="Rent"
                      outlined
                    ></v-text-field>
                  </div>

                  <div class="width50">
                    <v-text-field
                      v-model="priceValuation"
                      placeholder="Offer Price"
                      label="Offer Price"
                      outlined
                    ></v-text-field>
                  </div>

                  <div class="width50">
                    <v-dialog
                      v-model="startMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      class="modelBox"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateAdded"
                          label="Date"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="formInput mt-0"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="dateAdded"
                        @input="startMenu = false"
                      ></v-date-picker>
                    </v-dialog>
                  </div>
                  <div class="width100">
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="Comment"
                      placeholder="Comment"
                      rows="4"
                    ></v-textarea>
                  </div>
                </div>
              </v-col>
              <v-col md="12" class="pt-0">
                <v-btn @click="commentAdd">Save</v-btn>
              </v-col>
            </v-form>
          </div>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
export default {
  name: "TestProperties",
  computed: {
    ...mapGetters([
      "allSpecificMenus",
      "allRightMovesSaleDetail",
      "allLoadingProperty",
      "allPostCodes",
      "allPostCodePrefix",
      "allPrefixPostCode",
      "allRightmoveDownload",
      "allLoadingDownload",
      "allNorthSideDetails",
      "allRegion",
      "allComments",
    ]),
  },
  components: {
    Loading,
  },
  data() {
    return {
      price: 0,
      comments: [{ val: "" }],
      filtersection: false,
      changePostCode: null,
      changePrefixCode: null,
      changePreCode: null,
      loading: false,
      page: 1,
      limit: 500,
      loadingEmailInquiry: false,
      resloadingEmailInquiry: false,
      reoloadingEmailInquiry: false,
      issloadingEmailInquiry: false,
      startMenu: false,
      area: "",
      postcode: "",
      bedmins: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "min 10"],
      bedmaxs: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "max 10"],
      minprice: "",
      maxprice: "",
      minyield: "",
      maxyield: "",
      propertyTypes: [
        "Detached",
        "Semi-Detached",
        "Terraced",
        "Flat",
        "Bungalow",
        "Land",
      ],
      estateAgentEmails: [
        { name: "Richard", email: "richard@truevals.co.uk" },
        { name: "Alex", email: "alex@truevals.co.uk" },
        { name: "Binayak", email: "binayakdhakal999@gmail.com" },
      ],
      emailViews: [
        { name: "All", val: null },
        { name: "Yes", val: true },
        { name: "No", val: false },
      ],
      emailView: false,
      emailReplies: [
        { name: "All", val: null },
        { name: "Yes", val: "Yes" },
        { name: "No", val: "No" },
        { name: "Not Mine", val: "Not Mine" },
      ],
      emailReply: "",

      emailSents: [
        { name: "All", val: null },
        { name: "Yes", val: true },
        { name: "No", val: false },
      ],
      emailSent: false,

      reemailSents: [
        { name: "All", val: null },
        { name: "Yes", val: true },
        { name: "No", val: false },
      ],
      reemailSent: false,

      reoemailSents: [
        { name: "All", val: null },
        { name: "Yes", val: true },
        { name: "No", val: false },
      ],
      reoemailSent: false,

      issemailSents: [
        { name: "All", val: null },
        { name: "Yes", val: true },
        { name: "No", val: false },
      ],
      issemailSent: false,

      estateAgentEmail: "",
      propertyType: ["Detached", "Semi-Detached", "Terraced", "Bungalow"],
      soldStcs: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],

      regions: [
        "EastMidlands",
        "WestMidlands",
        "NorthEast",
        "NorthWest",
        "SouthEast",
        "SouthWest",
        "London",
      ],
      websites: [
        { text: "Zoopla", val: "zoopla" },
        { text: "Rightmoves", val: "rightmoves" },
      ],
      region: ["NorthEast", "NorthWest"],

      website: ["rightmoves", "zoopla"],
      soldstc: false,
      retirementHomes: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      retirementHome: false,
      sharedOwnerships: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      sharedOwnership: false,
      newbuilds: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      newbuild: false,
      austionProperties: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      auctionProperty: false,
      needRefurbishments: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      isListing: true,
      isListingLives: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      isParkHomes: [
        { text: "All", val: null },
        { text: "Yes", val: true },
        { text: "No", val: false },
      ],
      isParkHome: false,
      needRefurbishment: null,
      maxbed: "",
      minbed: "",
      filterCondition: {},
      jsondata: [],
      prefixpostcode: null,
      prepostcode: "",
      minRentYield: "",
      minLhaYield: "",
      optionOnProperties: [
        "All",
        "Accepted",
        "No Longer Available",
        "Unsuitable",
        "Offered",
        "Not Emailed",
        "Not Replied",
        "Emailed",
        "Re-Emailed",
        "Re-Offered",
      ],
      optionOnProperty: "Not Emailed",
      popStatus: false,
      tablist: ["formtab", "formlist"],
      tab: "formtab",
      singleProperty: undefined,
      priceValuation: undefined,
      lhaRent: undefined,
      rentValuation: undefined,
      comment: undefined,
      dateAdded: undefined,
      loadingComments: false,
      offerPrice: undefined,
      productId: "",
    };
  },
  watch: {
    async changePostCode(v) {
      this.loading = true;
      await this.getPostCodes({ postcode: v });
      this.loading = false;
    },
    async changePreCode(v) {
      this.loading = true;
      await this.getPrePostCodes({ postcode: v });
      this.loading = false;
    },
    async changePrefixCode(v) {
      this.loading = true;
      await this.getPrefixPostCodes({ postcode: v });
      this.loading = false;
    },
  },
  methods: {
    ...mapActions([
      "fetchPaginationPropertyDetails",
      "getRightMovesSalesDetail",
      "actionFuction",
      "getPropertyFilterRigthmoves",
      "getPostCodes",
      "getPrePostCodes",
      "getPrefixPostCodes",
      "getFilterDownload",
      "getTestDetail",
      "getRegion",
      "getPropertyInquiry",
      "resgetPropertyInquiry",
      "reogetPropertyInquiry",
      "issgetPropertyInquiry",
      "listOfComments",
      "addComment",
    ]),
    async popAction(data) {
      this.loadingComments = true;
      this.popStatus = !this.popStatus;
      this.singleProperty = data;
      this.lhaRent = this.singleProperty.lhaRent;
      this.rentValuation = this.singleProperty.averageRent;
      this.priceValuation = this.singleProperty.averagePriceValution;
      this.offerPrice = this.singleProperty.price;

      this.productId = data.productId;

      await this.listOfComments({
        productId: data.propertyId,
        limit: 10,
        page: 1,
      });
      this.loadingComments = false;
    },

    async sendInquiry() {
      this.loadingEmailInquiry = true;
      let filterBox = {};
      this.jsondata = [];
      filterBox.prefixpostcode = this.prefixpostcode;
      if (this.isListing) {
        filterBox.isListingLive = this.isListing;
      }
      if (this.maxbed != "") {
        filterBox.maxbed = this.maxbed;
      }
      if (this.estateAgentEmail != "") {
        filterBox.estateAgentEmail = this.estateAgentEmail;
      }
      filterBox.emailView = this.emailView;
      if (this.emailReply != "") {
        filterBox.emailReply = this.emailReply;
      }
      filterBox.emailSent = this.emailSent;
      filterBox.reemailSent = this.reemailSent;
      filterBox.reoemailSent = this.reoemailSent;
      filterBox.issemailSent = this.issemailSent;

      if (this.area != "") {
        filterBox.area = this.area;
      }
      if (this.minbed != "") {
        filterBox.minbed = this.minbed;
      }

      if (this.minLhaYield != "") {
        filterBox.minLhaYield = this.minLhaYield;
      }
      if (this.minRentYield != "") {
        filterBox.minRentYield = this.minRentYield;
      }
      if (this.maxprice != "") {
        filterBox.maxprice = this.maxprice;
      }

      if (this.minprice != "") {
        filterBox.minprice = this.minprice;
      }

      if (this.propertyType != "") {
        filterBox.houseSubtype = this.propertyType;
      }
      if (this.region.length > 0) {
        filterBox.region = this.region;
      }
      if (this.website.length > 0) {
        filterBox.website = this.website;
      }

      filterBox.soldstc = this.soldstc;

      filterBox.retirementHome = this.retirementHome;

      filterBox.sharedOwnership = this.sharedOwnership;

      filterBox.newBuild = this.newbuild;

      filterBox.auctionProperty = this.auctionProperty;

      filterBox.needRefurbishment = this.needRefurbishment;

      filterBox.isParkHome = this.isParkHome;
      filterBox.radius = null;
      filterBox.limit = this.limit;
      filterBox.page = 1;
      if (this.estateAgentEmail != "") {
        filterBox.estateAgentEmail = this.estateAgentEmail;
      }
      filterBox.emailView = this.emailView;
      if (this.emailReply != "") {
        filterBox.emailReply = this.emailReply;
      }
      filterBox.emailStatus = this.optionOnProperty;
      filterBox.emailSent = this.emailSent;
      await this.getPropertyInquiry(filterBox);
      this.loadingEmailInquiry = false;
    },
    async commentAdd() {
      this.loadingComments = true;
      // this.comments.push({ val: "" });
      let validateData = {};

      if (this.priceValuation != this.singleProperty.averagePriceValution) {
        validateData.priceValuation = this.priceValuation;
      }
      if (this.offerPrice != this.singleProperty.price) {
        validateData.offerPrice = this.offerPrice;
      }
      if (this.lhaRent != this.singleProperty.lhaRent) {
        validateData.lhaRent = this.lhaRent;
      }
      if (this.rentValuation != this.singleProperty.averageRent) {
        validateData.rentValuation = this.rentValuation;
      }
      if (this.comment != undefined) {
        validateData.comment = this.comment;
      }
      if (this.dateAdded != undefined) {
        validateData.dateAdded = this.dateAdded;
      }

      validateData.emailStatus = this.optionOnProperty;

      validateData.productId = this.singleProperty.propertyId;

      await this.addComment(validateData);
      this.loadingComments = false;
    },
    removeComment(index) {
      this.comments.splice(index, 1);
    },
    toggleFilter() {
      this.filtersection = !this.filtersection;
    },
    async nextPage(nt) {
      this.jsondata = [];
      if (this.filterCondition) {
        if (this.allNorthSideDetails.pagination.next != undefined) {
          await this.getTestDetail({
            ...this.filterCondition,
            limit: this.limit,
            page: nt,
          });
        }
      } else {
        if (this.allNorthSideDetails.pagination.next != undefined) {
          await this.getTestDetail({
            limit: this.limit,
            page: nt,
          });
        }
      }

      this.jsondata = [];
    },
    async prevPage(pt) {
      this.jsondata = [];
      if (this.filterCondition) {
        if (this.allNorthSideDetails.pagination.previous != undefined) {
          await this.getTestDetail({
            ...this.filterCondition,
            limit: this.limit,
            page: pt,
          });
        }
      } else {
        if (this.allNorthSideDetails.pagination.previous != undefined) {
          await this.getTestDetail({
            limit: this.limit,
            page: pt,
          });
        }
      }
      this.jsondata = [];
    },
    async filterData() {
      this.jsondata = [];
      this.filterCondition.prefixpostcode = this.prefixpostcode;
      if (this.isListing) {
        this.filterCondition.isListingLive = this.isListing;
      }
      if (this.maxbed != "") {
        this.filterCondition.maxbed = this.maxbed;
      }
      if (this.estateAgentEmail != "") {
        this.filterCondition.estateAgentEmail = this.estateAgentEmail;
      }
      this.filterCondition.emailView = this.emailView;
      if (this.emailReply != "") {
        this.filterCondition.emailReply = this.emailReply;
      }
      this.filterCondition.emailSent = this.emailSent;
      this.filterCondition.reemailSent = this.reemailSent;
      this.filterCondition.reoemailSent = this.reoemailSent;
      this.filterCondition.issemailSent = this.issemailSent;
      this.filterCondition.emailStatus = this.optionOnProperty;

      if (this.area != "") {
        this.filterCondition.area = this.area;
      }
      if (this.minbed != "") {
        this.filterCondition.minbed = this.minbed;
      }

      if (this.minLhaYield != "") {
        this.filterCondition.minLhaYield = this.minLhaYield;
      }
      if (this.minRentYield != "") {
        this.filterCondition.minRentYield = this.minRentYield;
      }
      if (this.maxprice != "") {
        this.filterCondition.maxprice = this.maxprice;
      }

      if (this.minprice != "") {
        this.filterCondition.minprice = this.minprice;
      }

      if (this.propertyType != "") {
        this.filterCondition.houseSubtype = this.propertyType;
      }
      if (this.region.length > 0) {
        this.filterCondition.region = this.region;
      }
      if (this.website.length > 0) {
        this.filterCondition.website = this.website;
      }

      this.filterCondition.soldstc = this.soldstc;

      this.filterCondition.retirementHome = this.retirementHome;

      this.filterCondition.sharedOwnership = this.sharedOwnership;

      this.filterCondition.newBuild = this.newbuild;

      this.filterCondition.auctionProperty = this.auctionProperty;

      this.filterCondition.needRefurbishment = this.needRefurbishment;

      this.filterCondition.isParkHome = this.isParkHome;
      this.filterCondition.radius = null;
      this.filterCondition.limit = this.limit;
      this.filterCondition.page = 1;

      await this.getTestDetail(this.filterCondition);
    },

    async sendEmail() {
      await this.getFilterDownload(this.filterCondition);
    },
    tabAction(tab) {
      this.tab = tab;
    },
  },
  async created() {
    await this.filterData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.marginMinus {
  margin-top: -30px;
}
.no-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}
h4 {
  margin-bottom: 30px;
}
.downloadbtn {
  margin-left: 10px;
}
.flagBox {
  height: 20px;
  border-radius: 50%;
  width: 20px;
}
.red {
  background: red;
}
.blue {
  background: rgb(45, 34, 199);
}
.fixPop {
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  justify-content: center;
  z-index: 99999;

  .v-form {
    width: 500px;
  }
  .formBox {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #fff;
    min-height: 300px;
    width: 550px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 50px;
    position: relative;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}

.commentTab {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  li {
    padding: 10px 15px;
    background: #efefef;
    border-radius: 5px;
    width: 100%;
    p {
      margin-bottom: 0;
    }
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.tabList {
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin-bottom: 10px;
  margin-top: 20px;
  li {
    border: 1px solid #efefef;
    margin: 0;
    width: 100%;
    padding: 8px 3px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
    background: #fff;
  }
}
.flexbox {
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
  margin-top: 40px;
  &:first-of-type {
    margin-bottom: 0;
  }
  .v-btn {
    margin-top: -25px;
    margin-left: 10px;
    position: absolute;
    top: -20px;
    right: 0;
  }
}
.width100 {
  width: 100%;
}
.flexBoxes {
  display: flex;
  flex-wrap: wrap;
}
.width50 {
  width: 50%;
  padding: 0 5px;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
    }
  }
}
.actionbtn {
  margin-bottom: 20px;
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -20px;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
</style>
