<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12" class="d-flex justify-space-between mb-4">
          <div>
            <v-btn
              class="leftSec addButton mr-2"
              @click="onCsvClick"
              :loading="importLoading"
            >
              Import Properties
            </v-btn>
            <input
              type="file"
              style="display: none"
              @change="onCsvPicked"
              ref="fileCSV"
              accept="csv/*"
            />
            <v-btn
              class="leftSec addButton"
              @click="navigateToAddProperty"
              style="margin-left: 65px;"
            >
              Add Property
            </v-btn>
          </div>
        </v-col>

        <v-col md="12" v-if="propertyLoading">
          <Loading />
        </v-col>

        <v-col md="12" v-else>
          <v-btn @click="deleteLastUploaded" :loading="loadingDelete">
            Delete the last uploaded import
          </v-btn>
        </v-col>

        <v-col md="12" v-if="!propertyLoading">
          <section>
            <v-row>
              <v-col md="12">
                <v-text-field
                  v-model="searchQuery"
                  label="Search by Address"
                  class="mb-4"
                  @input="filterProperties"
                ></v-text-field>
                <h3 class="table-heading d-flex justify-space-between align-center">
                  <span>Off Market Properties</span>
                  <span class="ml-auto">Total Properties: {{ totalPropertiesCount }}</span>
                </h3>
                <v-simple-table class="tableborder">
                  <thead>
                    <tr>
                      <td class="iconSection" colspan="12">
                        <div class="d-flex flexend align-center">
                          <v-btn @click="prevPage">
                            <v-icon>mdi-chevron-left</v-icon> Previous
                          </v-btn>
                          <strong class="px-3">
                            {{ currentStartIndex }} - {{ currentEndIndex }}
                          </strong>
                          <v-btn @click="nextPage">
                            Next <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>Publish</th>
                      <th>S/N</th>
                      <th width="200">Address</th>
                      <th>Bedrooms</th>
                      <th>Price</th>
                      <th width="200">Postcode</th>
                      <th>Gross Yield</th>
                      <th>Rent</th>
                      <th>Tenure Type</th>
                      <th>Property Type</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="filteredProperties.length == 0">
                      <td colspan="12">No Property Found</td>
                    </tr>
                    <tr v-for="(data, index) in filteredProperties" :key="index">
                      <td>
                        <v-switch
                          v-model="data.published"
                          @change="onPublish(data._id)"
                          color="primary"
                        ></v-switch>
                      </td>
                      <td>{{ (page - 1) * limit + index + 1 }}</td>
                      <td>{{ data.doorNumber }}, {{ data.address }}</td>
                      <td>{{ data.bedrooms }}</td>
                      <td>£{{ formatNumber(data.purchasePrice) }}</td>
                      <td>{{ data.address.split(',').pop() }}</td>
                      <td>{{ data.grossYield }}%</td>
                      <td>£{{ formatNumber(data.averageRent) }}</td>
                      <td>{{ data.tenureType }}</td>
                      <td>{{ data.propertyType }}</td>
                      <td>
                        <a :href="`/dashboard/offmarketproperty/${data._id}`" target="_blank">
                          <v-icon class="editIcon ml-2">mdi-eye</v-icon>
                        </a>
                      </td>
                      <td>
                        <v-icon
                          class="editIcon ml-2 delete-icon"
                          v-if="!loadingSingleDelete || propertyID != data._id"
                          @click="() => deleteSinglePropertyAct(data._id)"
                        >
                          mdi-delete
                        </v-icon>
                        <v-progress-circular
                          indeterminate
                          size="20"
                          width="3"
                          v-if="loadingSingleDelete && propertyID == data._id"
                        ></v-progress-circular>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="iconSection" colspan="12">
                        <div class="d-flex flexend align-center">
                          <v-btn @click="prevPage">
                            <v-icon>mdi-chevron-left</v-icon> Previous
                          </v-btn>
                          <strong class="px-3">
                            {{ currentStartIndex }} - {{ currentEndIndex }}
                          </strong>
                          <v-btn @click="nextPage">
                            Next <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </v-col>
            </v-row>
          </section>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">{{ text }}</v-snackbar>
    <v-snackbar v-model="snackbarpublish">{{ message }}</v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";

export default {
  name: "OffMarketProperties",
  components: {
    Loading,
  },
  data() {
    return {
      snackbar: false,
      text: "",
      properties: [],
      importLoading: false,
      propertyLoading: true,
      loadingDelete: false,
      loadingSingleDelete: false,
      propertyID: undefined,
      message: undefined,
      snackbarpublish: false,
      limit: 50,
      page: 1,
      searchQuery: '', // Added data property for search query
      filteredProperties: [], // Added data property for filtered properties
    };
  },
  computed: {
    ...mapGetters(["allImportCSV", "allWhiteLabels", "allComments"]),
    currentStartIndex() {
      return (this.page - 1) * this.limit + 1;
    },
    currentEndIndex() {
      return Math.min(this.page * this.limit, this.filteredProperties.length);
    },
    totalPropertiesCount() {
      return this.searchQuery ? this.filteredProperties.length : this.allImportCSV.pagination.total;
    },
  },
  watch: {
    properties: {
      handler() {
        this.filterProperties();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "postImportCSV",
      "getOffMarketProperties",
      "postPropertyPost",
      "deleteCSV",
      "deleteSingleProperty",
    ]),
    onCsvClick() {
      this.$refs.fileCSV.click();
    },
    navigateToAddProperty() {
      this.$router.push({ name: "AddPropertyMarket" });
    },
    async nextPage() {
      if (this.page * this.limit < this.allImportCSV.pagination.total) {
        this.page++;
        await this.getOffMarketProperties({ limit: this.limit, page: this.page });
        this.properties = this.allImportCSV;
      }
    },
    async prevPage() {
      if (this.page > 1) {
        this.page--;
        await this.getOffMarketProperties({ limit: this.limit, page: this.page });
        this.properties = this.allImportCSV;
      }
    },
    async deleteSinglePropertyAct(id) {
      this.propertyID = id;
      this.loadingSingleDelete = true;
      if (confirm(`Are you sure you want to delete this property? ${id}`)) {
        await this.deleteSingleProperty(id);
        this.snackbarpublish = true;
        this.message = this.allWhiteLabels.message;
      }
      this.loadingSingleDelete = false;
      await this.getOffMarketProperties({ limit: this.limit, page: this.page });
      this.properties = this.allImportCSV;
    },
    async deleteLastUploaded() {
      this.loadingDelete = true;
      if (confirm("Are you sure you want to delete the last uploaded import?")) {
        await this.deleteCSV();
        this.snackbarpublish = true;
        this.message = this.allComments.message;
      }
      this.loadingDelete = false;
      await this.getOffMarketProperties({ limit: this.limit, page: this.page });
      this.properties = this.allImportCSV;
    },
    async onCsvPicked(event) {
      this.importLoading = true;
      const file = event.target.files[0];
      let formData = new FormData();
      formData.append("csv", file);
      await this.postImportCSV(formData);
      this.snackbar = true;
      this.text = this.allImportCSV.message;
      this.properties = this.allImportCSV;
      this.importLoading = false;
    },
    async onPublish(id) {
      if (confirm("Are you sure you want to publish this property?")) {
        await this.postPropertyPost({ _id: id, propertyFrom: "offMarket" });
        this.snackbarpublish = true;
        this.message = this.allImportCSV.message;
        await this.getOffMarketProperties({ limit: this.limit, page: this.page });
        this.properties = this.allImportCSV;
      }
    },
    filterProperties() {
      const query = this.searchQuery.toLowerCase();
      this.filteredProperties = this.properties.results.filter(property => {
        return property.address.toLowerCase().includes(query);
      });
    },
    formatNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  async created() {
    await this.getOffMarketProperties({ limit: this.limit, page: this.page });
    this.properties = this.allImportCSV;
    this.propertyLoading = false;
    this.filterProperties(); // Initialize filtered properties
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.ml-auto {
  margin-left: auto;
}

.flexend {
  justify-content: flex-end;
  .v-btn {
    margin-left: 10px;
  }
}

.mb-4 {
  margin-bottom: 30px;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.leftSec {
  position: fixed;
  top: 15px !important;
  width: 150px !important;
  z-index: 999999;
  &:nth-of-type(1) {
    left: 280px;
  }
  &:nth-of-type(2) {
    left: 420px;
  }
}

.addButton {
  min-width: 200px !important;
}

.editIcon:hover,
.delete-icon:hover {
  color: #3f51b5;
  cursor: pointer;
}
</style>
