<template>
  <section>
    <div class="px-5 mt-5">
      <v-row v-if="userDetail.userType == 'admin'">
        <v-col md="12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row v-if="userDetail.userType != 'admin'">
        <v-col md="12">
          <h1>You are not attorization to access the setting panel.</h1>
        </v-col>
      </v-row>
      <v-row v-if="userDetail.userType == 'admin'">
        <v-col md="4">
          <div class="mb-3">
            <label for="">Yield Required</label>
          </div>

          <v-text-field
            v-model="value"
            placeholder="Yeild Required"
            label="Yield Required"
            outlined
          ></v-text-field>
          <v-btn v-if="!submitLoad" @click="validate('yieldRequired')"
            >Save</v-btn
          >
          <v-btn v-if="submitLoad"
            ><v-progress-circular indeterminate></v-progress-circular
          ></v-btn>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/dashboard/Loading";
export default {
  name: "Setting",
  data: () => ({
    loading: false,
    valueated: true,
    value: 0,
    submitLoad: false,
    userDetail: undefined,
  }),
  components: {
    Loading,
  },
  methods: {
    ...mapActions(["dashboardControl", "getDashboradControl"]),
    valuatedAction(data) {
      this.valueated = data;
    },
    async validate(data) {
      this.submitLoad = true;
      await this.dashboardControl({ title: data, value: this.value });
      this.submitLoad = false;
    },
    getUser() {
      return JSON.parse(localStorage.getItem("token")).user;
    },
  },
  computed: {
    ...mapGetters(["allDashboardControl"]),
  },
  async created() {
    await this.getDashboradControl({ title: "yieldRequired" });
    this.value = this.allDashboardControl.results.value;

    this.userDetail = this.getUser();
    this.loading = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.dashboardCard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  div {
    width: 23%;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    box-shadow: 0 3px 10px #e9e8e8;
    margin-bottom: 20px;
    margin: 10px;
    h1 {
      color: #0b5e8b;
    }
    strong {
      font-size: 35px;
      color: $menucolor;
    }
  }
}
.filterDropdown {
  display: inline-block;
  text-align: left;
  position: relative;
  &:hover {
    ul {
      display: block;
    }
  }
  div {
    width: 250px;
    border: 1px solid #efefef;
    padding: 10px;
  }
  .v-icon {
    font-size: 18px;
  }
  ul {
    position: absolute;
    top: 100%;
    width: 250px;
    list-style: none;
    padding-left: 0;
    z-index: 999;
    display: none;
    li {
      padding: 10px 15px;
      box-shadow: 0 2px 3px #efefef;
      background: #fff;
      cursor: pointer;
    }
    margin-bottom: 0;
  }
}
</style>