<template>
  <section class="mt-5">
    <div v-if="!loading">
      <Loading />
    </div>

    <div v-if="loading">
      <div class="fixedLoading">
        <span>Refetching the property data from rightmove.</span>
        <v-progress-circular
          v-if="loadingPropertyUpdate"
          indeterminate
          size="20"
          width="3"
          color="primary"
        ></v-progress-circular>
        <span class="checkGreen" v-if="!loadingPropertyUpdate">
          <v-icon> mdi-check </v-icon>
        </span>
        <div class="downloadBtn text-right">
          <download-csv :data="csvData" :name="property.propertyId + '.csv'">
            Download CSV
            <v-icon> mdi-download </v-icon>
          </download-csv>
        </div>
      </div>
      <div class="flexBox iconText viewListingButton">
        <div class="width100 text-right">
          <a
            :href="`https://www.rightmove.co.uk/properties/${property.propertyId}`"
            target="blank"
            ><v-btn large> View Listing </v-btn></a
          >
        </div>
      </div>
      <v-container class="contain">
        <v-row>
          <v-col md="10">
            <h1 v-if="property.houseNumber == ''">
              {{ property.detailAddress.displayAddress }}
            </h1>
            <h1 v-if="property.houseNumber != ''">
              {{ property.fullAddress }}
            </h1>
            <p class="text-capitalize">
              {{ property.houseType }}, {{ property.houseSubtype }}
            </p>
          </v-col>
          <v-col md="2">
            <h1 class="text-right priceBox">
              ₤ {{ numberFormat(property.price) }}

              <v-icon @click="wishlistAct" v-if="!property.wishlist">
                mdi-heart-outline
              </v-icon>
              <v-icon
                @click="wishlistAct"
                class="wishlist"
                v-if="property.wishlist"
              >
                mdi-heart
              </v-icon>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <img src="@/assets/images/house.svg" alt="" />
              <div>
                <span>Property Type</span>
                <h2 class="text-capitalize">{{ property.houseSubtype }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <img src="@/assets/images/bed.svg" alt="" />
              <div>
                <span>Bedroom</span>
                <h2>{{ property.bedrooms }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <img src="@/assets/images/bathroom.svg" alt="" />
              <div>
                <span>Bathroom</span>
                <h2>{{ property.bathrooms }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Door Number</span>
                <v-text-field
                  dense
                  @keyup="formDataChange"
                  outlined
                  placeholder="Door"
                  v-model="houseNumber"
                  append-icon="mdi-square-edit-outline"
                ></v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Tenure</span>
                <h2>{{ property.tenureType }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Listed On</span>
                <h2>{{ property.listedDate }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Sold STC Status</span>
                <h2>{{ property.soldStc ? "SOLD STC" : "Still Available" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="2">
            <div class="flexBox iconText">
              <div>
                <span>Listing Status</span>
                <h2>
                  {{
                    !property.isListingLive
                      ? "Off From The Market"
                      : "Still Live"
                  }}
                </h2>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="6" cols="12">
            <h3 class="mb-5">Key Features :</h3>
            <ul class="keyFeatures">
              <li
                v-for="(data, index) in property.propertykeyFeatures"
                :key="index + 'keyfeature'"
              >
                {{ data }}
              </li>
            </ul>
          </v-col>
          <v-col md="6" cols="12">
            <h3 class="mb-5">Property Photos :</h3>
            <slick ref="slick" :options="slickOptions">
              <div
                class="image"
                v-for="(data, index) in property.images"
                :key="'as' + index"
              >
                <img :src="data.url" class="imageBox" alt="" />
              </div>
            </slick>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>About the Agent</h3>
          </v-col>

          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Name</span>
                <h2>
                  {{ property.brandName }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Phone</span>
                <h2>
                  {{ property.agentPhone }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Email Status</span>
                <h2>-</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Email Status Date</span>
                <h2>-</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Agent Address</span>
                <h2>
                  {{ property.agentAddress }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Agent Email</span>
                <v-text-field
                  @keyup="formDataChange"
                  dense
                  outlined
                  placeholder="Agent Email"
                  v-model="estateAgentEmail"
                  append-icon="mdi-square-edit-outline"
                ></v-text-field>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Agent Contact</span>
                <v-text-field
                  @keyup="formDataChange"
                  dense
                  outlined
                  placeholder="Agent Name"
                  v-model="estateAgentName"
                  append-icon="mdi-square-edit-outline"
                ></v-text-field>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Financials</h3>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Asking Price</span>
                ₤ {{ numberFormat(property.price) }}
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Valuation</span>
                <div class="d-flex justify-space-between align-start">
                  <v-text-field
                    v-if="editValuation"
                    dense
                    outlined
                    @keyup="formDataChange"
                    prepend-inner-icon="₤ "
                    placeholder="Valuation"
                    v-model="averagePriceValution"
                  ></v-text-field>

                  <div v-if="!editValuation">
                    ₤ {{ numberFormat(averagePriceValution) }}
                  </div>
                  <v-icon
                    v-if="!editValuation"
                    @click="() => (editValuation = true)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                  <v-icon
                    v-if="editValuation"
                    @click="() => (editValuation = false)"
                  >
                    mdi-check
                  </v-icon>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Discount to Market</span>
                <h2>{{ property.discountToMarket }}%</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Max Offer</span>
                <h2
                  v-if="
                    averagePriceValution >
                    (averageRent * 12) / (yieldRequired / 100)
                  "
                >
                  ₤
                  {{ numberFormat((averageRent * 12) / (yieldRequired / 100)) }}
                </h2>
                <h2
                  v-if="
                    averagePriceValution <
                    (averageRent * 12) / (yieldRequired / 100)
                  "
                >
                  ₤
                  {{ numberFormat(averagePriceValution) }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment</span>

                <div class="d-flex justify-space-between align-start">
                  <v-text-field
                    v-if="editRefurbishmentCost"
                    dense
                    @keyup="formDataChange"
                    outlined
                    prepend-inner-icon="₤ "
                    placeholder="Refurbishment Cost"
                    v-model="refurbishmentCost"
                  ></v-text-field>
                  <div v-if="!editRefurbishmentCost">
                    <div v-if="!refurbishmentCost">
                      ₤ {{ refurbishmentCost }}
                    </div>
                    <div v-if="refurbishmentCost">
                      ₤ {{ numberFormat(refurbishmentCost) }}
                    </div>
                  </div>
                  <v-icon
                    v-if="!editRefurbishmentCost"
                    @click="() => (editRefurbishmentCost = true)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                  <v-icon
                    v-if="editRefurbishmentCost"
                    @click="() => (editRefurbishmentCost = false)"
                  >
                    mdi-check
                  </v-icon>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>RoCe</span>
                <h2>{{ property.returnToMarket }}%</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Current Offer</span>
                <h2 v-if="property.offerPrice != undefined">
                  ₤ {{ property.offerPrice }}
                </h2>
                <h2 v-if="property.offerPrice == undefined">₤ 0</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3" v-if="averageRent != undefined">
            <div class="flexBox iconText">
              <div class="fullwidth">
                <span>Market Rent</span>
                <div class="d-flex justify-space-between align-start">
                  <v-text-field
                    v-if="editMarketRent"
                    dense
                    @keyup="formDataChange"
                    outlined
                    prepend-inner-icon="₤ "
                    placeholder="Market Rent"
                    v-model="averageRent"
                  ></v-text-field>
                  <div v-if="!editMarketRent">
                    ₤ {{ numberFormat(averageRent) }}
                  </div>
                  <v-icon
                    v-if="!editMarketRent"
                    @click="() => (editMarketRent = true)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                  <v-icon
                    v-if="editMarketRent"
                    @click="() => (editMarketRent = false)"
                  >
                    mdi-check
                  </v-icon>
                  <v-progress-circular
                    size="15"
                    width="2"
                    class="mt-1"
                    indeterminate
                    v-if="loadingRentAssign"
                  ></v-progress-circular>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3" v-if="averageRent != undefined">
            <div class="flexBox iconText">
              <div>
                <span>Rent Confidence</span>
                <h2 v-if="property.rentValutionConfidence <= 1">High</h2>
                <h2
                  v-if="
                    property.rentValutionConfidence > 1 &&
                    property.rentValutionConfidence <= 3
                  "
                >
                  Medium
                </h2>
                <h2 v-if="property.rentValutionConfidence >= 3">Low</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3" v-if="property.rentGrossYieldN != undefined">
            <div class="flexBox iconText">
              <div>
                <span>Market Gross Yield</span>
                <h2>{{ property.rentGrossYieldN }}%</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3" v-if="property.rentNetYieldN != undefined">
            <div class="flexBox iconText">
              <div>
                <span>Market Net Yield</span>
                <h2>{{ property.rentNetYieldN }}%</h2>
              </div>
            </div>
          </v-col>

          <v-col md="3" v-if="property.rentGrossYieldN == undefined">
            <div class="flexBox iconText">
              <div>
                <span>Market Gross Yield</span>
                <h2>{{ property.rentGrossYield }}%</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3" v-if="property.rentNetYieldN == undefined">
            <div class="flexBox iconText">
              <div>
                <span>Market Net Yield</span>
                <h2>{{ property.rentNetYield }}%</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>LHA Rent</span>
                <h2>₤ {{ numberFormat(property.lhaRent) }}</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>LHA Year</span>
                <h2>2022</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>LHA Gross Yield</span>
                <h2>{{ property.lhaGrossYield }}%</h2>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>LHA Net Yield</span>
                <h2>{{ property.lhaNetYield }}%</h2>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <h3>Due Dilligence</h3>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>EPC Rating</span>
                <div>
                  <div
                    class="fixedBox"
                    v-if="popStatus[0] && property.epc != '-'"
                    @click="popStatusAct(0)"
                  >
                    <img :src="property.epc" alt="" />
                  </div>
                </div>

                <div v-if="property.epcReal != undefined">
                  {{ property.epcReal }}
                </div>

                <div v-if="property.epcReal == undefined">
                  <v-icon v-if="property.epc != '-'" @click="popStatusAct(0)">
                    mdi-eye
                  </v-icon>
                  <span v-if="property.epc == '-' && loadingEpcAssign">
                    loading
                  </span>
                  <span v-if="property.epc == '-' && !loadingEpcAssign">
                    -
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3" v-if="property.expiryDate != undefined">
            <div class="flexBox iconText">
              <div>
                <span>Valid Until</span>
                <div>
                  <div
                    class="fixedBox"
                    v-if="popStatus[0] && property.epc != '-'"
                    @click="popStatusAct(0)"
                  >
                    <img :src="property.epc" alt="" />
                  </div>
                </div>

                <div>
                  {{ property.expiryDate }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div>
                <span>Floor Area</span>
                <div>
                  <div
                    class="fixedBox"
                    v-if="popStatus[1] && property.floorPlan != '-'"
                    @click="popStatusAct(1)"
                  >
                    <img :src="property.floorPlan" alt="" />
                  </div>
                </div>

                <div v-if="property.epcReal != undefined">
                  {{ property.floorsqft }}
                </div>
                <div v-if="property.epcReal == undefined">
                  <v-icon
                    v-if="property.floorPlan != '-'"
                    @click="popStatusAct(1)"
                  >
                    mdi-eye
                  </v-icon>
                  <span v-if="property.floorPlan == '-'"> - </span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col md="3">
            <div class="flexBox iconText">
              <div class="widthFull">
                <span class="d-flex flex-box-space-between"
                  >Flood Risk
                  <div v-if="property.floodRisk == undefined">
                    <v-btn
                      small
                      v-if="!loadingFloodRisk && floodriskPresent"
                      @click="getFloodriskAct"
                      >Load Floodrisk</v-btn
                    >
                    <a
                      href="https://check-long-term-flood-risk.service.gov.uk/postcode"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <v-btn
                        small
                        v-if="!loadingFloodRisk && !floodriskPresent"
                        @click="getFloodriskAct"
                        >Look On Gov Website</v-btn
                      ></a
                    >
                    <v-btn small v-if="loadingFloodRisk" loading></v-btn>
                  </div>
                </span>

                <ul class="floodRisk" v-if="property.floodRisk">
                  <li
                    :class="{
                      highRisk: property.floodRisk.surface == 'High risk',
                    }"
                  >
                    <div>
                      <h3>Surface water</h3>
                      <p>{{ property.floodRisk.surface }}</p>
                    </div>
                  </li>
                  <li
                    :class="{
                      highRisk: property.floodRisk.riverAndSea == 'High risk',
                    }"
                  >
                    <div>
                      <h3>Rivers and the sea</h3>
                      <p>{{ property.floodRisk.riverAndSea }}</p>
                    </div>
                  </li>
                  <li
                    :class="{
                      highRisk: property.floodRisk.reservoir == 'High risk',
                    }"
                  >
                    <div>
                      <h3>Reservoirs</h3>
                      <p>
                        {{
                          property.floodRisk.reservoir == null
                            ? "Not Found"
                            : property.floodRisk.reservoir
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col md="6">
            <div class="flexBox iconText mrTopMinus">
              <div class="widthFull">
                <span class="d-flex flex-box-space-between"
                  >Rent Comparable Properties
                </span>
                <ul class="floodRisk" v-if="property.rentComparable">
                  <li v-if="property.rentComparable.length == 0">
                    No Comparables Found
                  </li>
                  <li v-if="property.rentComparable.length > 0">
                    <div>
                      <table border="" class="table-border ml-0">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Postcode</th>
                            <th>Bed</th>
                            <th>Market Rent</th>
                            <th>Distance</th>
                            <th>Source</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(dat, index) in property.rentComparable"
                            :key="index + 'rentdata'"
                          >
                            <td>
                              <a
                                :href="`https://www.rightmove.co.uk/properties/${dat.propertyId}`"
                                target="_blank"
                                rel="noopener noreferrer"
                                >{{ dat.address }}</a
                              >
                            </td>
                            <td>{{ dat.postcode }}</td>
                            <td>{{ dat.bedrooms }}</td>
                            <td>₤ {{ numberFormat(dat.price) }}</td>
                            <td>
                              <span v-if="dat.radius"
                                >{{ dat.radius.toFixed(2) }} mi</span
                              >
                            </td>
                            <!-- <td></td> -->
                            <td>
                              <a
                                :href="`https://www.rightmove.co.uk/property-to-rent/find.html?locationIdentifier=${dat.outcode}&maxBedrooms=${dat.bedrooms}&minBedrooms=${dat.bedrooms}&radius=${dat.searchRadius}&propertyTypes=bungalow%2Cdetached%2Csemi-detached%2Cterraced&includeLetAgreed=false&mustHave=&dontShow=&furnishTypes=&keywords=`"
                                target="_blank"
                                rel="noopener noreferrer"
                                >Verify</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <v-col md="6">
            <div class="flexBox iconText mrTopMinus">
              <div class="widthFull">
                <span class="d-flex flex-box-space-between"
                  >Sold Comparables
                </span>
                <ul class="floodRisk" v-if="property.rentComparable">
                  <li v-if="property.rentComparable.length == 0">
                    No Comparables Found
                  </li>
                  <li v-if="property.rentComparable.length > 0">
                    <div>
                      <table border="" class="table-border ml-0">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Postcode</th>
                            <th>Sold Price</th>
                            <th>Property Type</th>
                            <th>Tenure</th>

                            <th>Sold On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(dat, index) in property.saleComparable"
                            :key="index + 'rentdata'"
                          >
                            <td>{{ dat.doorNumber }}, {{ dat.street }}</td>
                            <td width="100">{{ dat.postcode }}</td>
                            <td width="100">₤ {{ numberFormat(dat.price) }}</td>
                            <td>
                              {{
                                dat.propertyType == "T"
                                  ? "Terraced"
                                  : dat.propertyType == "SD"
                                  ? "Semi-Detached"
                                  : dat.propertyType == "D"
                                  ? "Detached"
                                  : dat.propertyType
                              }}
                            </td>
                            <td>
                              {{ dat.tenure == "F" ? "Freehold" : "Leasehold" }}
                            </td>

                            <td width="100">
                              {{ dat.date | moment("DD/MM/YYYY") }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="pt-10">
            <h3>Comment</h3>
          </v-col>
          <PropertyUpdate
            v-if="loading"
            :item="propertyUpdate"
            @updateComment="updatePropertyAct"
            :propertyStatus="property.propertyStatus"
            :property="property"
          />
        </v-row>
        <v-row class="bgColor pb-5">
          <v-col md="12" class="pt-10">
            <h3>Internal Use Only</h3>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Reference</span>
                <h2>{{ property._id }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Auction</span>
                <h2>{{ property.auction ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Retirement</span>
                <h2>{{ property.retirementHome ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Park Home</span>
                <h2>{{ property.parkHome ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Shared Ownership</span>
                <h2>{{ property.sharedOwnership ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>New Build</span>
                <h2>{{ property.newBuild ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="flexBox iconText">
              <div>
                <span>Refurbishment</span>
                <h2>{{ property.refurbishment ? "Y" : "N" }}</h2>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/dashboard/Loading";
import PropertyUpdate from "@/components/dashboard/PropertyUpdate";
import { mapActions, mapGetters } from "vuex";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
export default {
  name: "SingleProperty",
  data: () => ({
    property: undefined,
    commentData: undefined,
    loading: false,
    markRentEdit: false,
    floodriskPresent: true,
    refurbEstimate: undefined,
    slickOptions: {
      slidesToShow: 1,
      dots: false,
      arrows: true,
      fade: false,
    },
    popStatus: [false, false],
    yieldRequired: undefined,
    propertyStatuses: [
      "Offered",
      "Re-Offered",
      "Accepted",
      "Unsuitable",
      "No Longer Available",
    ],
    estateAgentEmail: undefined,
    estateAgentName: undefined,
    houseNumber: undefined,
    refurbishmentCost: undefined,
    averageRent: undefined,
    averagePriceValution: undefined,
    propertyUpdate: undefined,
    loadingFloodRisk: false,
    loadingRentAssign: true,
    loadingEpcAssign: true,
    loadingPropertyUpdate: true,
    editValuation: false,
    editMarketRent: false,
    editRefurbishmentCost: false,
    csvData: [],
  }),
  components: {
    PropertyUpdate,
    Loading,
    Slick,
  },
  computed: {
    ...mapGetters([
      "allSpecificMenus",
      "allSingleProperty",
      "allDashboardControl",
    ]),
  },
  methods: {
    ...mapActions([
      "getSinglePropertyDetails",
      "getDashboradControl",
      "updateProperty",
      "postWishlist",
      "getFloodrisk",
      "getRentAssign",
      "getEpcAssign",
      "getPropertyUpdate",
    ]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    formDataChange() {
      if (this.property.estateAgentEmail != this.estateAgentEmail) {
        this.propertyUpdate.estateAgentEmail = this.estateAgentEmail;
      }
      if (this.property.estateAgentName != this.estateAgentName) {
        this.propertyUpdate.estateAgentName = this.estateAgentName;
      }
      if (this.property.houseNumber != this.houseNumber) {
        this.propertyUpdate.houseNumber = this.houseNumber;
      }
      if (this.property.refurbishmentCost != this.refurbishmentCost) {
        this.propertyUpdate.refurbishmentCost = this.refurbishmentCost;
      }
      if (this.property.averageRent != this.averageRent) {
        this.propertyUpdate.averageRent = this.averageRent;
      }
      if (this.property.averagePriceValution != this.averagePriceValution) {
        this.propertyUpdate.averagePriceValution = this.averagePriceValution;
      }
    },
    async updatePropertyAct() {
      await this.updateProperty(this.propertyUpdate);
      this.propertyUpdate = undefined;
      this.property = this.allSingleProperty.results;
      this.houseNumber = this.allSingleProperty.results.houseNumber;
      this.estateAgentEmail = this.allSingleProperty.results.estateAgentEmail;
      this.estateAgentName = this.allSingleProperty.results.estateAgentName;
      this.refurbishmentCost = this.allSingleProperty.results.refurbishmentCost;
      this.averageRent =
        this.allSingleProperty.results.averageRentN != undefined
          ? this.allSingleProperty.results.averageRentN
          : this.allSingleProperty.results.averageRent;
      this.averagePriceValution =
        this.allSingleProperty.results.averagePriceValution;
      this.propertyUpdate = {
        propertyId: this.allSingleProperty.results.propertyId,
        _id: this.allSingleProperty.results._id,
      };
    },
    popStatusAct(i) {
      if (i == 0 && this.popStatus[i] == false) {
        this.popStatus = [true, false];
      } else if (i == 0 && this.popStatus[i] == true) {
        this.popStatus = [false, false];
      }
      if (i == 1 && this.popStatus[i] == false) {
        this.popStatus = [false, true];
      } else if (i == 1 && this.popStatus[i] == true) {
        this.popStatus = [false, false];
      }
    },
    async wishlistAct() {
      this.property.wishlist = !this.property.wishlist;
      await this.postWishlist({
        propertyId: this.property.propertyId,
        propertyFor: "sale",
      });
    },
    async getFloodriskAct() {
      this.loadingFloodRisk = true;
      await this.getFloodrisk(this.property._id);
      this.property = this.allSingleProperty.results;
      this.loadingFloodRisk = false;
      if (this.property.floodRisk == undefined) {
        this.floodriskPresent = false;
      }
    },
    async getRentAssignAct() {
      this.loadingRentAssign = true;
      await this.getRentAssign(this.property._id);
      this.property = this.allSingleProperty.results;
      this.averageRent =
        this.allSingleProperty.results.averageRentN != undefined
          ? this.allSingleProperty.results.averageRentN
          : this.allSingleProperty.results.averageRent;
      this.loadingRentAssign = false;
    },
    async getEpcAssignAct() {
      this.loadingEpcAssign = true;
      await this.getEpcAssign(this.property._id);
      this.property = this.allSingleProperty.results;
      this.loadingEpcAssign = false;
    },
    async getPropertyUpdateAct() {
      this.loadingPropertyUpdate = true;
      await this.getPropertyUpdate(this.property._id);
      this.property = this.allSingleProperty.results;
      this.loadingPropertyUpdate = false;
    },
  },
  async created() {
    await this.getSinglePropertyDetails(this.$route.params.id);
    await this.getDashboradControl({ title: "yieldRequired" });

    this.yieldRequired = this.allDashboardControl.results.value;
    this.property = this.allSingleProperty.results;
    this.houseNumber = this.allSingleProperty.results.houseNumber;
    this.estateAgentEmail = this.allSingleProperty.results.estateAgentEmail;
    this.estateAgentName = this.allSingleProperty.results.estateAgentName;
    this.refurbishmentCost = this.allSingleProperty.results.refurbishmentCost;
    this.averageRent =
      this.allSingleProperty.results.averageRentN != undefined
        ? this.allSingleProperty.results.averageRentN
        : this.allSingleProperty.results.averageRent;
    this.averagePriceValution =
      this.allSingleProperty.results.averagePriceValution;
    this.propertyUpdate = {
      propertyId: this.allSingleProperty.results.propertyId,
      _id: this.allSingleProperty.results._id,
    };
    this.loading = true;

    if (this.property.houseNumber != undefined) {
      await this.getEpcAssignAct();
    }
    await this.getPropertyUpdateAct();
    await this.getRentAssignAct();

    this.csvData = [
      {
        DoorNumber: this.property.houseNumber,
        StreetAddress: this.property.detailAddress.displayAddress,
        FullPostcode: this.property.postcode,
        PropertyCardURLLink: `https://truevals.com/dashboard/singlePropert/${this.property._id}`,
        RightmoveURLLink: `https://www.rightmove.co.uk/properties/${this.property.propertyId}`,
        PropertyType: this.property.houseSubtype,
        Tenure: this.property.tenureType,
        NumberOfBedrooms: this.property.bedrooms,
        AskingPrice: this.property.price,
        Valuation: this.property.averagePriceValution,
        MarketRent: this.property.averageRent,
        MarketRentConfidence:
          this.property.rentValutionConfidence < 1
            ? "High"
            : this.property.rentValutionConfidence <= 3
            ? "Medium"
            : "Low",
        LHARent: this.property.lhaRent,
        LHAYear: 2022,
        RefurbishmentCosts: this.refurbishmentCost,
        EPCRating: this.property.epcReal,
        FloorArea: this.property.floorsqft,
        EPCExpiry: this.property.expiryDate,
        FloodRiskSurface: this.property.floodRisk.surface,
        FloodRiskRiverAndSea: this.property.floodRisk.riverAndSea,
        FloodRiskReservoir: this.property.floodRisk.reservoir,
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
h1 {
  font-size: 30px;
}
h2 {
  font-size: 18px;
  font-weight: 400;
}
.iconText {
  img {
    margin-right: 15px;
  }
  span {
    font-size: 15px;
    display: block;
    margin-bottom: 5px;
  }
}
.listSingle {
  list-style: none;
  margin-top: 10px;
  padding-left: 20px;
  li {
    line-height: 30px;
  }
}
.imageproperty {
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
  }
  img {
    width: 300px;
  }
}
.keyFeatures {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: -5px;
  li {
    margin: 5px 20px;
    font-size: 18px;
    width: 40%;
  }
}
.table-border {
  margin-top: 15px;
  margin-left: 20px;
  border-collapse: collapse;
  th {
    padding: 10px;
  }
  td {
    padding: 10px;
  }
}
.editBox {
  display: flex;
  align-items: center;
  font-weight: 600;
  .v-text-field {
    margin-bottom: -20px;
    font-weight: 600;
  }
}
.width100 {
  width: 100% !important;
  .v-btn {
    width: 80%;
  }
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    display: flex;
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
      // color: $white;
    }
  }
}

.images {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 4px;
  }
  img {
    width: 220px;
  }
}
.flexBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  strong {
    margin-top: 5px;
    display: flex;
    width: 30px;
  }
  .textBox {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 10px;
    margin-left: 10px;
  }
}
.imageBox {
  background: #f4f4f4;
  height: 350px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
h3 {
  color: #09395a;
  font-size: 24px;
}
.boxborder {
  margin-top: 10px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 30px;
  background: $white;
  h3 {
    margin-bottom: 0;
  }
}
.fullwidth {
  width: 100%;
  .v-text-field {
    margin-top: 5px;
  }
}
.bgColor {
  margin-top: 30px;
  position: relative;
  div {
    position: relative;
    z-index: 9;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: #f4f4f4;
    left: -500px;
    right: -500px;
    height: 100%;
  }
}
.contain {
  width: 1200px;
  // left: -200px;
  // position: relative;
}
.fixedBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  text-align: center;
  img {
    width: 700px;
    margin: 100px;
  }
}
.uk-sticky {
  margin: 0;
  z-index: 999999;
  width: 200px;
  text-decoration: none;
}

.floodRisk {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  h3 {
    font-size: 16px;
  }
}
.flexBox {
  span {
    font-weight: 600;
    color: #09395a;
  }
}
.priceBox {
  .v-icon {
    margin-left: 20px;
    font-size: 30px !important;
    &:hover {
      cursor: pointer;
    }
  }
  .wishlist {
    color: red;
  }
}
.widthFull {
  width: 100%;
}
.flex-box-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.fixedLoading {
  position: fixed;
  top: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}
.checkGreen {
  display: inline-flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: green;
  align-items: center;
  justify-content: center;
  .v-icon {
    color: #fff;
    font-size: 16px !important;
  }
}
.viewListingButton {
  position: fixed;
  top: 25px;
  z-index: 999;
  height: 10px;
  width: 150px;
  right: 200px;
  a{
    text-decoration: none;
  }
}
.downloadBtn {
  width: 200px;
  cursor: pointer;
  div {
    width: 160px;
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    background: $tagcolor;
    color: #fff;
    font-size: 14px;
    .v-icon {
      color: #fff;
      margin-left: 10px;
    }
  }
}
.highRisk {
  background: rgba(255, 0, 0, 0.2);
}
</style>